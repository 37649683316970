import React from 'react';
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TrackRow = ({track}) => (
    <div className='group justify-center items-center w-64 h-64 overflow-y-hidden border-1 border-gray-200 shadow rounded-xl'>
        <div className='border-2 rounded-xl w-full h-48'>
            <GatsbyImage 
                className="w-full h-full rounded-xl opacity-90 group-hover:opacity-50"
                image={getImage(track.image)}
                alt={track.title}
            />
        </div>
        <div className='relative 
                        h-48 
                        rounded-md p-2 mx-4 bg-white border-2 border-gray-200 
                        -mt-10 
                        group-hover:border-blue-100 group-hover:shadow 
                        group-hover:-translate-y-32 duration-200'>
            <Link to={track.path} className='absolute top-0 left-0 h-full w-full rounded-md'/>

            <div className='flex-auto text-lg font-semibold'>
                {track.title}
            </div>
            <div className=''>
                {track.levels.map(l => (
                    <span key={l} className='m-1 px-2 text-s bg-gray-200 rounded-full'>{ l }</span>
                ))}
            </div>
            <div className='w-full flex flex-wrap '>
                {track.subjects.map(l => (
                    <span key={l} className='m-1 px-2 text-xs bg-gray-200 rounded-full'>{ l }</span>
                ))}
            </div>
            {/* <div className='flex px-10'>
                {track.activities.map(a => (
                    <div key={a}>{a}</div>
                ))}
            </div> */}
            {/* <div className='flex px-10'>
                {track.subjects.map(a => (
                    <div key={a}>{a}</div>
                ))}
            </div> */}
            {/* <div className='flex'>
                {track.levels.map(a => (
                    <div className='ml-2' key={a}>{a}</div>
                ))}
            </div> */}
        </div>
    </div>
)

export default TrackRow;