import React, { useState, useEffect } from "react"
import DefaultLayout from "../layouts/default";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import TrackRow from "../components/TrackRow";
import { makeTrackPath } from "../utils/slug";
import Multiselect from 'multiselect-react-dropdown';
import useTracks from "../utils/useTracks";

import scenariuszeGrafika from "../images/scenariusze_grafika.svg"
import scenariuszeTekst from "../images/scenariusze_tekst.svg"



const TracksPage = ({ data }) => {
    const tracks = data.allNodeTrack.nodes.map((track) => ({
        title: track.title,
        activities: track.relationships.field_activity.map(a => (a.name)),
        levels: track.relationships.field_level.map(a => (a.name)),
        subjects: [...new Set(track.relationships.field_point.map(p => (p.relationships.field_subject.map(a => (a.name))[0])))],
        path: makeTrackPath(track),
        image: track.relationships.field_image?.localFile || null,
        municipality: track.relationships.field_gmina ? track.relationships.field_gmina.name : null,
    }));

    const subjects = data.allTaxonomyTermSubject.nodes.map(a => a.name)
    const [subjectsFilters, setSubjectsFilters] = useState([])

    const activities = data.allTaxonomyTermActivity.nodes.map(a => a.name)
    const [activitiesFilters, setActivitiesFilters] = useState([])

    const levels = data.allTaxonomyTermLevel.nodes.map(a => a.name)
    const [levelsFilters, setLevelsFilters] = useState([])

    const municipalities = data.allTaxonomyTermGmina.nodes.map(a => a.name)
    const [municipalitiesFilters, setMunicipalitiesFilters] = useState([])

    const [searchFilter, setSearchFilter] = useState("")

    const filteredTracks = useTracks(tracks, {activitiesFilters, levelsFilters, municipalitiesFilters, searchFilter, subjectsFilters})

    return (
        <DefaultLayout>
            <SEO title="Scenariusze" />
            <img className="h-72 mx-auto" src={scenariuszeGrafika} alt="scenariusze"></img>
            <img className="h-12 mx-auto mb-8" src={scenariuszeTekst} alt="scenariusze"></img>
            <div className="flex flex-row mb-3">
                <input
                    className="mx-1 px-1 w-1/3 border border-gray-500 rounded h-10"
                    type="text" placeholder="Szukaj po tytule" onChange={e => { setSearchFilter(e.target.value) }} />
            </div>
            <div className="flex flex-row">
                <div className="px-1 w-1/3">
                    <Multiselect
                        placeholder="Przedmiot"
                        options={subjects}
                        onSelect={f => setSubjectsFilters([...f])}
                        onRemove={f => setSubjectsFilters([...f])}
                        isObject={false}
                    />
                </div>
                <div className="px-1 w-1/3">
                    <Multiselect
                        placeholder="Poziom"
                        options={levels}
                        onSelect={f => setLevelsFilters([...f])}
                        onRemove={f => setLevelsFilters([...f])}
                        isObject={false}
                    />
                </div>
                <div className="px-1 w-1/3">
                    <Multiselect
                        placeholder="Aktywność"
                        options={activities}
                        onSelect={f => setActivitiesFilters([...f])}
                        onRemove={f => setActivitiesFilters([...f])}
                        isObject={false}
                    />
                </div>
                <div className="px-1 w-1/3">
                    <Multiselect
                        placeholder="Gmina"
                        options={municipalities}
                        onSelect={f => setMunicipalitiesFilters([...f])}
                        onRemove={f => setMunicipalitiesFilters([...f])}
                        isObject={false}
                    />
                </div>
            </div>
            <div className="pt-10 grid grid-cols-3 gap-y-4">
                {filteredTracks.map((track) => (
                    <TrackRow track={track} key={track.title} className=""></TrackRow>
                ))}
            </div>
        </DefaultLayout>
    )
}

export default TracksPage

export const query = graphql`
query {
    allTaxonomyTermLevel {
        nodes {
            name
        }
    }
    allTaxonomyTermActivity {
        nodes {
            name
        }
    }
    allTaxonomyTermGmina {
        nodes {
            name
        }
    }
    allTaxonomyTermSubject {
        nodes {
            name
        }
    }
    allNodeTrack(
        sort: {
            fields: [field_priority]
        }
    ) {
        nodes {
            title
            id: drupal_internal__nid
            relationships {
              field_gmina {
                name
              }
              field_activity {
                  name
              }
              field_level {
                  name
              }
              field_point {
                  relationships {
                      field_subject {
                          name
                      }
                  }
              }
              field_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      placeholder: BLURRED,
                    )
                  }
                }
              }
            }
        }
    }
  }
`